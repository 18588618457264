<template>
  <div class="animate-pulse flex flex-col gap-y-10 md:gap-y-14 lg:gap-y-16">
    <div class="w-full aspect-video bg-brand-greyblack-1020 backdrop-blur-sm">
      <div
        class="z-10 w-full h-full md:pt-[calc(5rem_+_246px)] lg:pt-[calc(5rem_+_56px)] px-4 md:px-10 lg:px-28 pb-4 bg-no-repeat bg-center bg-cover transition-opacity ease-in-out duration-75"
      >
        <div id="gridWrapper" class="z-10 w-full h-full flex items-end">
          <div class="z-10 w-full ">
            <div id="logo" class="bg-gray-200/10 w-40 h-12 rounded-lg"></div>
            <div
              id="assetInfo"
              class="mt-8 flex flex-col gap-y-4 hover:opacity-100 transition duration-200"
            >
              <div>
                <div class="flex flex-col gap-y-2">
                  <div class="flex items-center gap-x-3">
                    <div
                      v-for="i in 3"
                      class="rounded-full bg-gray-200/10 backdrop-blur-sm w-20 p-3"
                    ></div>
                  </div>
                  <div class="flex items-center gap-x-3">
                    <div
                      class="rounded-full bg-gray-200/10 backdrop-blur-sm w-16 p-3"
                    ></div>
                  </div>
                </div>
                <p
                  class="pt-4 md:text-body-medium lg:text-title-big tracking-wider text-white opacity-[0.87] line-clamp-3"
                >
                </p>
              </div>
            </div>
            <div id="cta" class="mt-6 rounded-lg flex gap-x-4">
              <div
                class="rounded-full bg-gray-200/10 backdrop-blur-sm w-24 py-5"
              ></div>
              <div
                class="rounded-full bg-gray-200/10 backdrop-blur-sm w-12 h-12"
              ></div>
              <div
                class="rounded-full bg-gray-200/10 backdrop-blur-sm w-12 h-12"
              ></div>
              <div
                class="rounded-full bg-gray-200/10 backdrop-blur-sm w-12 h-12"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="px-4 md:px-10 lg:px-28 flex">
      <div class="grid-container gap-2 md:gap-4 lg:gap-6">
        <div
          :key="key"
          v-for="(g, key) in 12"
          class="flex flex-col gap-y-2  lg:col-span-6 rounded-lg"
        >
          <div class="flex flex-col gap-y-2">
            <div class="bg-brand-greyblack-1020 rounded-sm w-1/3 h-6"></div>
            <div
              class="bg-brand-greyblack-1020 backdrop-blur-sm rounded-sm thumbnail medium aspect-video"
            ></div>
            <div
              class="bg-brand-greyblack-1020 backdrop-blur-sm rounded-sm w-4/6 h-5"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup></script>

<style lang="scss" scoped></style>
